import { isEmpty } from 'lodash'

import { UserDto } from '@marketplace-web/shared/user-data'
import { findThumbnail, ItemThumbnailSize } from '@marketplace-web/shared/utils'
import { getItemPhotosThumbnails, getItemThumbnail } from 'data/utils/item'
import {
  BundleItemDto,
  CatalogItemDto,
  ClosetPromotionItemDto,
  ItemPhotoDto,
  TransactionItemDto,
} from 'types/dtos'
import { ItemPhotoModel, ProductItemModel, ProductItemUserModel } from 'types/models'

import { transformCurrencyAmountDto, transformCurrencyAmountDtoOrString } from './currency-amount'
import { transformIconBadges } from './icon-badges'
import { transformItemPhotoDtos } from './item'
import { transformItemBoxDto } from './item-box'
import { transformPhotoDto } from './photo'

export const getDominantColor = (photos: Array<ItemPhotoDto>) => {
  if (isEmpty(photos)) return null

  const photo = photos.find(({ is_main }) => is_main === true)

  if (!photo) return null

  return photo.dominant_color_opaque
}

export const transformPartialUserDtoToProductItemUser = (
  user: Partial<Pick<UserDto, 'id' | 'login' | 'photo' | 'profile_url' | 'business'>>,
): ProductItemUserModel => ({
  id: user.id,
  login: user.login,
  photo: user.photo ? transformPhotoDto(user.photo) : null,
  thumbnailUrl: findThumbnail(user.photo),
  profileUrl: user.profile_url,
  isBusiness: user.business,
})

// TODO: Remove with transaction purge from BundleItems.tsx
// Only need from transaction order items is ids
export const transformItemDtoToProductItem = (item: TransactionItemDto): ProductItemModel => ({
  id: item.id,
  title: item.title,
  url: item.url,
  photos: transformItemPhotoDtos(item.photos),
  favouriteCount: 0,
  user: {},
  priceWithDiscount: null,
  price: transformCurrencyAmountDto(item.price),
  thumbnailUrl: getItemThumbnail(item.photos, ItemThumbnailSize.Large),
  dominantColor: getDominantColor(item.photos),
  badge: null,
  isFavourite: false,
  areStatsVisible: false,
})

export const transformBundleItemDtoToProductItem = (item: BundleItemDto): ProductItemModel => ({
  id: item.id,
  title: item.title,
  url: '',
  photos: transformItemPhotoDtos(item.photos),
  favouriteCount: item.favourite_count,
  user: transformPartialUserDtoToProductItemUser({ business: item.is_owner_business }),
  priceWithDiscount: item.discount_price ? transformCurrencyAmountDto(item.discount_price) : null,
  price: transformCurrencyAmountDto(item.price),
  totalItemPrice: item.total_item_price
    ? transformCurrencyAmountDto(item.total_item_price)
    : undefined,
  thumbnailUrl: getItemThumbnail(item.photos, ItemThumbnailSize.Large),
  dominantColor: getDominantColor(item.photos),
  badge: item.badge,
  isFavourite: item.is_favourite,
  iconBadges: transformIconBadges(item.icon_badges),
  itemBox: item.item_box && transformItemBoxDto(item.item_box),
  isHeavyBulky: item.is_heavy_bulky,
})

export const transformCatalogItemDtoToProductItem = (item: CatalogItemDto): ProductItemModel => ({
  id: item.id,
  title: item.title,
  url: item.url,
  favouriteCount: item.favourite_count,
  priceWithDiscount: item.discount
    ? transformCurrencyAmountDtoOrString(item.discount, item.discount.currency_code)
    : null,
  price: transformCurrencyAmountDtoOrString(item.price, item.price.currency_code),
  badge: item.badge,
  serviceFee: item.service_fee
    ? transformCurrencyAmountDtoOrString(item.service_fee, item.service_fee.currency_code)
    : null,
  totalItemPrice: item.total_item_price
    ? transformCurrencyAmountDtoOrString(item.total_item_price, item.total_item_price.currency_code)
    : undefined,
  isPromoted: item.promoted,
  isFavourite: item.is_favourite,
  thumbnailUrl: item.photo && getItemThumbnail([item.photo], ItemThumbnailSize.Large),
  dominantColor: item.photo && getDominantColor([item.photo]),
  user: transformPartialUserDtoToProductItemUser(item.user),
  photos: (item.photo && transformItemPhotoDtos([item.photo])) || [],
  iconBadges: transformIconBadges(item.icon_badges),
  viewCount: item.view_count,
  itemBox: item.item_box && transformItemBoxDto(item.item_box),
})

const transformClosetPromotionPhotos = (item: ClosetPromotionItemDto): Array<ItemPhotoModel> => {
  if (item.photos && item.photos.length > 0) {
    return transformItemPhotoDtos(item.photos)
  }

  if (!item.photo) return []

  return transformItemPhotoDtos([item.photo])
}

const tranaformClosetPromotionItemsThumbnails = (
  item: ClosetPromotionItemDto,
): Array<string> | undefined => {
  if (item.photos && item.photos.length > 0) {
    return getItemPhotosThumbnails(item.photos, ItemThumbnailSize.Large)
  }

  if (!item.photo) return undefined

  return getItemPhotosThumbnails([item.photo], ItemThumbnailSize.Large)
}

const tranaformClosetPromotionItemThumbnail = (item: ClosetPromotionItemDto): string | null => {
  if (item.photos && item.photos.length > 0) {
    return getItemThumbnail(item.photos, ItemThumbnailSize.Large)
  }

  if (!item.photo) return null

  return getItemThumbnail([item.photo], ItemThumbnailSize.Large)
}

const transformClosetPromotionItemDominantColor = (item: ClosetPromotionItemDto): string | null => {
  if (item.photos && item.photos.length > 0) {
    return getDominantColor(item.photos)
  }

  if (!item.photo) return null

  return getDominantColor([item.photo])
}

export const transformClosetPromotionItemDtoToProductItem = (
  item: ClosetPromotionItemDto,
): ProductItemModel => ({
  id: item.id,
  title: item.title,
  user: { id: item.user_id, isBusiness: item.business_user },
  url: item.url,
  favouriteCount: item.favourite_count,
  priceWithDiscount: item.discount
    ? transformCurrencyAmountDtoOrString(item.discount, item.currency)
    : null,
  price: transformCurrencyAmountDtoOrString(item.price, item.currency),
  serviceFee: item.service_fee
    ? transformCurrencyAmountDtoOrString(item.service_fee, item.currency)
    : null,
  totalItemPrice: item.total_item_price
    ? transformCurrencyAmountDtoOrString(item.total_item_price, item.currency)
    : undefined,
  badge: item.badge,
  isFavourite: item.is_favourite,
  thumbnailUrl: tranaformClosetPromotionItemThumbnail(item),
  thumbnailUrls: tranaformClosetPromotionItemsThumbnails(item),
  dominantColor: transformClosetPromotionItemDominantColor(item),
  photos: transformClosetPromotionPhotos(item),
  iconBadges: transformIconBadges(item.icon_badges),
  itemBox: item.item_box && transformItemBoxDto(item.item_box),
})

export const transformBundleItemDtosToProductItems = (
  items: Array<BundleItemDto>,
): Array<ProductItemModel> => items.map(item => transformBundleItemDtoToProductItem(item))

export const transformCatalogItemDtosToProductItems = (
  items: Array<CatalogItemDto>,
): Array<ProductItemModel> => items.map(transformCatalogItemDtoToProductItem)

export const transformClosetPromotionItemDtosToProductItems = (
  items: Array<ClosetPromotionItemDto>,
): Array<ProductItemModel> => items.map(transformClosetPromotionItemDtoToProductItem)
