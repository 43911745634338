import { ItemThumbnailSize, UserThumbnailSize } from '../constants/photo'

type Thumbnails = null | undefined | { thumbnails: Array<{ type: string; url: string }> }

export const findThumbnail = (
  photo: Thumbnails,
  size: UserThumbnailSize | ItemThumbnailSize = UserThumbnailSize.Default,
): string | null => {
  return photo?.thumbnails.find(({ type }) => type === size)?.url || null
}
