import { ShipmentInstructionsDto } from 'types/dtos'
import { ShipmentInstructions } from 'types/models'

import { transformCarrierDto } from 'data/transformers/carrier'
import { transformDropOffType } from 'data/transformers/drop-off'
import { transformReturnShippingOptionDto } from 'data/transformers/return-shipping-option'
import { transformPhotoDto } from 'data/transformers/photo'
import { transformCurrencyAmountDto } from 'data/transformers/currency-amount'

import { transformLabelFormatDetails } from './label-format-details'

export const transformShipmentInstructions = (
  response: ShipmentInstructionsDto,
): ShipmentInstructions => ({
  carrier: transformCarrierDto(response.carrier),
  carrierCode: response.carrier_code,
  hint: response.hint,
  id: response.id,
  instructions: response.instructions,
  currency: response.currency,
  price: response.price,
  priceTitle: response.price_title,
  receiverAddress: response.receiver_address,
  receiverFullAddress: response.receiver_full_address,
  receiverName: response.receiver_name,
  receiverTitle: response.receiver_title,
  receiverPhoto: response.receiver_photo && transformPhotoDto(response.receiver_photo),
  senderPhoto: response.sender_photo && transformPhotoDto(response.sender_photo),
  dropOffType: response.drop_off_type && transformDropOffType(response.drop_off_type),
  labelFormatDetails:
    response.label_format_details && transformLabelFormatDetails(response.label_format_details),
  returnShippingOption:
    response.return_shipping_option &&
    transformReturnShippingOptionDto(response.return_shipping_option),
  actionTitle: response.action_title,
  screenTitle: response.screen_title,
  shippingPrice: transformCurrencyAmountDto(response.shipping_price),
  dropOffMapEnabled: response.drop_off_map_enabled,
  carrierTermsAndConditions: response.carrier_terms_and_conditions
    ? response.carrier_terms_and_conditions
    : null,
})
