import {
  HowInviteInstructionWorksDto,
  ReferralRegistrationFriendDto,
  ReferralInviteInstructionsDto,
  ReferralInviteInstructionsNewDto,
  ReferralInviteInstructionsOldDto,
  ReferralRegistrationDto,
  ReferralRegistrationNewDto,
  ReferralRegistrationRewardDto,
  ReferralShareDto,
  ReferralRegistrationFriendsDto,
  ReferralRegistrationRewardsDto,
  ReferralRegistrationInfoBannerDto,
} from 'types/dtos'
import {
  HowInviteInstructionsWorksModel,
  ReferralInviteInstructionsModel,
  ReferralInviteInstructionsNewModel,
  ReferralInviteInstructionsOldModel,
  ReferralRegistrationFriendModel,
  ReferralRegistrationModel,
  ReferralRegistrationNewModel,
  ReferralRegistrationFriendsModel,
  ReferralRegistrationRewardModel,
  ReferralRegistrationRewardsModel,
  ReferralShareModel,
  ReferralRegistrationInfoBannerModel,
} from 'types/models'

export const transformReferralInviteInstructionsOldDto = (
  instructions: ReferralInviteInstructionsOldDto,
): ReferralInviteInstructionsOldModel => ({
  title: instructions.title,
  subtitle: instructions.subtitle,
  footer: instructions.footer,
  imageUrl: instructions.image_url,
  learnMoreUrl: instructions.learn_more_url || '',
  termsUrl: instructions.terms_url || '',
})

export const transformHowInviteInstructionWorksDto = (
  howItWorks: HowInviteInstructionWorksDto,
): HowInviteInstructionsWorksModel => ({
  title: howItWorks.title,
  subtitle: howItWorks.subtitle,
  imageUrl: howItWorks.image_url,
})

export const transformReferralInviteInstructionsNewDto = (
  instructions: ReferralInviteInstructionsNewDto,
): ReferralInviteInstructionsNewModel => ({
  ...transformReferralInviteInstructionsOldDto(instructions),
  howItWorks: instructions.how_it_works.map(transformHowInviteInstructionWorksDto),
  images: { ...instructions.images },
  referralsIcon: instructions.referrals_icon,
  howItWorksIcon: instructions.how_it_works_icon,
  referralsButtonTitle: instructions.referrals_button_title,
  howItWorksScreenTitle: instructions.how_it_works_screen_title,
})

export const transformReferralInviteInstructionsDto = (
  instructions: ReferralInviteInstructionsDto,
): ReferralInviteInstructionsModel => {
  if ('how_it_works' in instructions) return transformReferralInviteInstructionsNewDto(instructions)

  return transformReferralInviteInstructionsOldDto(instructions)
}

const transformReferralRegistrationDto = (
  referral: ReferralRegistrationDto,
): ReferralRegistrationModel => ({
  id: referral.id,
  name: referral.name,
  userId: referral.user_id,
  photoUrl: referral.photo_url,
  subtitle: referral.subtitle || '',
})

export const transformReferralOldRegistrationsDto = (
  referrals: Array<ReferralRegistrationDto>,
): Array<ReferralRegistrationModel> => referrals.map(transformReferralRegistrationDto)

export const transformReferralRegistrationFriendDto = (
  friend: ReferralRegistrationFriendDto,
): ReferralRegistrationFriendModel => ({
  userId: friend.user_id,
  name: friend.name,
  photoUrl: friend.photo_url,
  note: friend.note,
  completed: friend.completed,
  subtitle: friend.subtitle,
})

const transformReferralRegistrationInfoBannerDto = (
  banner: ReferralRegistrationInfoBannerDto,
): ReferralRegistrationInfoBannerModel => ({
  level: banner.level,
  title: banner.title || undefined,
  body: banner.body,
  screenName: banner.screen_name,
  extraNotice: !!banner.extra_notice,
})

export const transformReferralRegistrationFriendsDto = (
  friends: ReferralRegistrationFriendsDto,
): ReferralRegistrationFriendsModel => ({
  title: friends.title,
  footer: friends.footer,
  infoBanner:
    friends.info_banner && transformReferralRegistrationInfoBannerDto(friends.info_banner),
  list: friends.list.map(transformReferralRegistrationFriendDto),
  emptyState: friends.empty_state && {
    title: friends.empty_state.title,
    body: friends.empty_state.body,
    imageUrl: friends.empty_state.image_url,
  },
})

export const transformReferralRegistrationRewardDto = (
  reward: ReferralRegistrationRewardDto,
): ReferralRegistrationRewardModel => ({
  id: reward.id,
  title: reward.title,
  subtitle: reward.subtitle,
  status: reward.status,
})

export const transformReferralRegistrationRewardsDto = (
  rewards: ReferralRegistrationRewardsDto,
): ReferralRegistrationRewardsModel => ({
  title: rewards.title,
  footer: rewards.footer,
  infoBanner:
    rewards.info_banner && transformReferralRegistrationInfoBannerDto(rewards.info_banner),
  list: rewards.list.map(transformReferralRegistrationRewardDto),
  emptyState: rewards.empty_state && {
    title: rewards.empty_state.title,
    body: rewards.empty_state.body,
    imageUrl: rewards.empty_state.image_url,
  },
})

export const transformReferralRegistrationNewDto = (
  dto: ReferralRegistrationNewDto,
): ReferralRegistrationNewModel => ({
  screenTitle: dto.screen_title,
  referrals: transformReferralRegistrationFriendsDto(dto.referrals),
  rewards: transformReferralRegistrationRewardsDto(dto.rewards),
})

export const transformReferralShareDto = (share: ReferralShareDto): ReferralShareModel => ({
  isEnabled: share.is_enabled,
  inviteUrl: share.invite_url,
})
