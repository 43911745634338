import { metrics } from '@opentelemetry/api'
import { Resource } from '@opentelemetry/resources'
import {
  ConsoleMetricExporter,
  MeterProvider,
  MetricReader,
  PeriodicExportingMetricReader,
} from '@opentelemetry/sdk-metrics'
import {
  AggregationTemporalityPreference,
  OTLPMetricExporter,
} from '@opentelemetry/exporter-metrics-otlp-http'

const EXPORT_INTERVAL_MS = 30_000

type CounterOptions = {
  name: string
  value: number
  labels: Record<string, string | number>
}

type GaugeOptions = {
  name: string
  value: number
  labels: Record<string, string | number>
}

type HistogramOptions = {
  name: string
  value: number
  buckets: Array<number>
  labels: Record<string, string | number>
}

type InitializeOptions = {
  collectorUrl: string
  serviceName: string
  samplingRate?: number
  env?: string
  version?: string
  withConsoleExporter?: boolean
}

export class ClientMetricsOtel {
  isServerSide: boolean = typeof window === 'undefined'

  isEnabled: boolean = typeof window === 'undefined'

  withConsoleExporter: boolean = false

  collectorUrl: string = 'unknown'

  serviceName: string = 'unknown'

  environment?: string = undefined

  version?: string = undefined

  #setupProperties({
    serviceName,
    env,
    version,
    withConsoleExporter,
    collectorUrl,
    samplingRate = 1,
  }: InitializeOptions) {
    this.collectorUrl = collectorUrl
    this.serviceName = serviceName
    this.withConsoleExporter = withConsoleExporter || false

    this.environment = env
    this.version = version

    this.isEnabled = !this.isServerSide && Math.random() <= samplingRate
  }

  #getNameWithPrefix(name: string) {
    const { serviceName } = this

    return ['otel', serviceName, name].join('_')
  }

  #getResource() {
    const { serviceName, environment: env, version } = this

    return Resource.default().merge(
      new Resource({
        'service.name': serviceName,
        version,
        env,
      }),
    )
  }

  #getReaders() {
    const { collectorUrl } = this

    const readers: Array<MetricReader> = [
      new PeriodicExportingMetricReader({
        exporter: new OTLPMetricExporter({
          url: `${collectorUrl}/v1/metrics`,
          concurrencyLimit: 1,
          headers: {
            'Content-Type': 'application/json',
          },
          temporalityPreference: AggregationTemporalityPreference.CUMULATIVE,
        }),
        exportIntervalMillis: EXPORT_INTERVAL_MS,
      }),
    ]

    if (this.withConsoleExporter) {
      readers.push(
        new PeriodicExportingMetricReader({
          exporter: new ConsoleMetricExporter(),
          exportIntervalMillis: EXPORT_INTERVAL_MS,
        }),
      )
    }

    return readers
  }

  initialize(options: InitializeOptions) {
    this.#setupProperties(options)

    if (!this.isEnabled) return

    const resource = this.#getResource()
    const readers = this.#getReaders()

    //  Shutdown the exporter reader when the page is closed
    //  so that the last batch of metrics is sent
    if (!this.isServerSide) {
      window.addEventListener('beforeunload', () => {
        readers.forEach(reader => reader.shutdown())
      })
    }

    const meterProvider = new MeterProvider({
      resource,
      readers,
    })

    metrics.setGlobalMeterProvider(meterProvider)
  }

  counter({ name, value, labels }: CounterOptions) {
    if (!this.isEnabled) return

    const meter = metrics.getMeter(name)
    const counter = meter.createCounter(this.#getNameWithPrefix(name))

    counter.add(value, { ...labels })
  }

  gauge({ name, value, labels }: GaugeOptions) {
    if (!this.isEnabled) return

    const meter = metrics.getMeter(name)
    const gauge = meter.createObservableGauge(this.#getNameWithPrefix(name))

    gauge.addCallback(result => {
      result.observe(value, { ...labels })
    })
  }

  histogram({ name, value, buckets, labels }: HistogramOptions) {
    if (!this.isEnabled) return

    const meter = metrics.getMeter(name)
    const histogram = meter.createHistogram(this.#getNameWithPrefix(name), {
      advice: {
        explicitBucketBoundaries: buckets,
      },
    })

    histogram.record(value, { ...labels })
  }
}

const clientMetricsOtel = new ClientMetricsOtel()

export default clientMetricsOtel
