import { LabelFormatDetailsDto } from 'types/dtos'
import { LabelFormatDetailsModel } from 'types/models'

export const transformLabelFormatDetails = (
  labelFormatDetail: LabelFormatDetailsDto,
): LabelFormatDetailsModel => ({
  iconUrl: labelFormatDetail.icon_url,
  title: labelFormatDetail.title,
  subtitle: labelFormatDetail.subtitle,
})
