import { EscrowOrderShipmentDto, EscrowOrderShipmentCarrierDto } from 'types/dtos'
import { EscrowOrderShipmentModel, EscrowOrderShipmentCarrierModel } from 'types/models'

export const transformEscrowOrderShipmentCarrierDto = (
  dto: EscrowOrderShipmentCarrierDto,
): EscrowOrderShipmentCarrierModel => ({
  iconUrl: dto.icon,
  name: dto.name,
  dropOffMapAvailable: dto.drop_off_map_available,
  dropOffPointUrl: dto.drop_off_point_url,
})

export const transformEscrowOrderShipmentDto = (
  dto: EscrowOrderShipmentDto,
): EscrowOrderShipmentModel => ({
  id: dto.id,
  senderId: dto.sender_id,
  receiverId: dto.receiver_id,
  status: dto.status,
  type: dto.type,
  entryType: dto.entry_type,
  pickupType: dto.pickup_type,
  pickupPointAddress: dto.pickup_point_address,
  carrier: transformEscrowOrderShipmentCarrierDto(dto.carrier),
})
