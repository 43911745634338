import { ItemBoxDto } from 'types/dtos'
import { ItemBoxModel } from 'types/models'

export const transformItemBoxDto = (itemBox: ItemBoxDto): ItemBoxModel => ({
  firstLine: itemBox.first_line,
  secondLine: itemBox.second_line,
  exposure: itemBox.exposure,
  accessibilityLabel: itemBox.accessibility_label,
  badge: itemBox.badge && { title: itemBox.badge.title },
})
