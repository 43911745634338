import { ShippingPointDto, ShippingOpenHoursDto, ShippingDayDto } from 'types/dtos'
import { ShippingPointModel, ShippingOpenHoursModel, ShippingDayModel } from 'types/models'

export const transformShippingOpenHoursDto = ({
  opens_at,
  closes_at,
}: ShippingOpenHoursDto): ShippingOpenHoursModel => ({
  opensAt: opens_at,
  closesAt: closes_at,
})

export const transformShippingBusinessHoursDto = ({
  weekday,
  label,
  hours,
}: ShippingDayDto): ShippingDayModel => ({
  weekday,
  label,
  hours: hours?.map(transformShippingOpenHoursDto),
})

export const transformShippingPointDto = ({
  uuid,
  code,
  carrier_code,
  carrier_id,
  latitude,
  longitude,
  name,
  city,
  description,
  address_line1,
  address_line2,
  postal_code,
  photo_url,
  business_hours,
  labels,
  badges,
  is_selected,
}: ShippingPointDto): ShippingPointModel => ({
  uuid,
  code,
  carrierCode: carrier_code,
  carrierId: carrier_id || null,
  latitude,
  longitude,
  name,
  city,
  description,
  addressLine1: address_line1,
  addressLine2: address_line2,
  postalCode: postal_code,
  photoUrl: photo_url,
  businessHours: business_hours?.map(transformShippingBusinessHoursDto),
  labels,
  badges: badges || null,
  isSelected: is_selected || null,
})
