import { findThumbnail } from '@marketplace-web/shared/utils'
import { calculateRating } from 'data/utils/user-rating'
import { ClosetDto, ClosetPromotionUserDto } from 'types/dtos'
import { ClosetModel, ClosetUserModel } from 'types/models'

export const transformClosetUser = (user: ClosetPromotionUserDto): ClosetUserModel => {
  return {
    id: user.id,
    name: user.login,
    image: findThumbnail(user.photo),
    rating: calculateRating(user.feedback_reputation),
    ratingCount: user.feedback_count,
    isFollowed: user.is_favourite,
    itemCount: user.item_count,
  }
}

export const transformCloset = (closet: ClosetDto): ClosetModel => {
  const { items, user } = closet

  return {
    user: transformClosetUser(user),
    items,
    showBanner: false,
  }
}

export const transformClosets = (data: Array<ClosetDto>): Array<ClosetModel> =>
  data.map(transformCloset)
